import { Injectable } from '@angular/core';
import { Project } from '../model/form/project';
import { ProjectResponse } from '../model/response/project-response';
import { HttpClient } from '@angular/common/http';
import { ProjectRequest } from '../model/request/project-request';
import { catchError, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { ProjectSummaryResponse } from '../model/response/ProjectSummaryResponse';
import { Image } from '../model/Image';
import { Share } from '../model/share';
import { Vcu } from '../model/form/vcu';
import { VcuRequest } from '../model/request/vcu-request';
import { Vehicle } from '../model/vehicle';
import { ProjectSharing } from '../model/project-sharing';
import { VcuResponse } from '../model/response/vcu-response';
import { Item } from '../model/Item';
import { Equipment } from '../model/equipment';
import { DEFAULT_IMAGE } from '../model/mockup/image-mockup';
import { CreateProjectPostReq } from '../model/request/create-project-post-req';
import { UpdateProjectPutReq } from '../model/request/update-project-put-req';
import { ProjectMapping } from '../model/project-mapping';
import { MapProject } from 'src/app/model/map-project';
import { ProjectLogs } from '../model/project-logs';
import { LogProject } from 'src/app/model/log-project';
import { CreateProjectSharingPostResp } from '../model/response/create-project-sharing-post-resp';
import { VcuService } from './vcu.service';
import { ProjectEquipments } from '../model/project-equipments';
import { ProjectsFindUserVcusGetResp } from '../model/response/find-user-vcus-get-resp';
import { Status } from '../model/enum/status';

@Injectable({providedIn: 'root'})
export class ProjectService {

  httpOptions: {};
  projectRequest: ProjectRequest;
  projectUrl = enviroment.BASE_URL_API + '/user';  

  constructor(private http: HttpClient) {
    this.httpOptions = {};
    this.projectRequest = new ProjectRequest();
  }

  // getProject(userID: number) : Observable<ProjectResponse> {
  //   return this.http.get<ProjectResponse>(this.projectUrl + '/'+ userID, this.httpOptions).pipe(
  //     tap(),
  //     catchError(this.handleError<ProjectResponse>(`getProject userID=${userID}`))
  //   );
  // }

  findAllProjectSummary(userID: string, statusProject?: string, statusVCU?: string ) : Observable<ProjectSummaryResponse[]> {
    let url =`${this.projectUrl}/${userID}/project`;
    if(statusProject && statusVCU){
      url = `${url}?statusProject=${statusProject}&statusVcu=${statusVCU}`
    }else if(statusProject){
      url = `${url}?statusProject=${statusProject}`
    }else if(statusVCU){
      url = `${url}?statusVcu=${statusVCU}`
    }        
    return this.http.get<ProjectSummaryResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ProjectSummaryResponse[]>(`findAllProjectSummary`))
    );
  }

  getProject(userID: string, projectID: string) : Observable<ProjectResponse> {
    return this.http.get<ProjectResponse>(this.projectUrl +'/' + userID + '/project/' + projectID, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ProjectResponse>(`getProject`))
    );
  }

  createProject(userID: string, project: CreateProjectPostReq) {
    return this.http.post<ProjectResponse>(this.projectUrl + '/'+ userID + '/project', project, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ProjectResponse>('addProject'))
    );
  }

  updateProject(userID: string, projectID: string, project: UpdateProjectPutReq) : Observable<ProjectResponse> {
    return this.http.put<ProjectResponse>(this.projectUrl +'/' + userID + '/project/' + projectID, project, this.httpOptions).pipe(
      tap()
    );
  }
  
  // deleteProject(id: number) : Observable<ProjectResponse> {
  //   return this.http.delete<ProjectResponse>(this.projectUrl + '/'+ id, this.httpOptions).pipe(
  //     tap(),
  //     catchError(this.handleError<ProjectResponse>('deleteProject'))
  //   );
  // }

  async getProjectDefault(userID: string) : Promise<Project | null> {
    return new Promise((resolve) => {
      this.findAllProjectSummary(userID).subscribe(data => {
        if(data) {
          let projects : Project[] = this.projectSummaryToProject(data);
          let projectsFavorite : Project[] = projects.filter(pj => pj.favorite);
          console.log('PROJECT FAVORITE', projectsFavorite)
          if(projectsFavorite.length > 0) 
            resolve(projectsFavorite[0]);
        } else {
          resolve(null);
        }
      });
    });
  }

  
  projectSummaryToProject(projectSumResp: ProjectSummaryResponse[]) : Project[] {  
    if(projectSumResp.length < 1) return [];

    let projects: Project[] = [];
    projectSumResp.forEach(psr => {
      let equipments: Equipment[] = [];
      psr.nickname.forEach( eq =>{
        let equipment = new Equipment(1,eq,0,'');
        equipments.push(equipment);
      });

      const project = new Project(
        psr.projectID,
        psr.default,
        psr.projectName,
        null,
        null,
        null,
        psr.tags,
        '',
        [],
        equipments,
        psr.primaryImage ? [ new Image(0, psr.primaryImage)] : [],
        psr.attributes?.primaryImage ? new Image(0, psr.attributes?.primaryImage): null,
        undefined,
        [],
        [],
        [],
        '',
        psr.attributes,
        '',
        psr.ownerUser ? psr.ownerUser : ''
      );
      projects.push(project);            
    });
    return projects;
  }

  toProjectRequest(project: Project, vehicle: Vehicle) {
    //TODO converter campos e montar a REQUEST do backend
    project.vehicle = vehicle;
    return new ProjectRequest(
      project.name,
      project.favorite,
      {
        "date-of-manufacture": project.vehicle?.yearDate?.toISOString().split('T')[0] ?? '',
        detail: project.detail,
        manufacturer: project.vehicle?.manufacturer ?? '',
        photo: this.imageToString(project.images),
        primaryImage: project.primaryImage?.name ?? '',
        "vehicle-model": project.vehicle?.model ?? '',
      },
      this.shareToProjectSharing(project.sharedWith),
      project.tags,
      this.vcuToVcuRequest(project.vcus)
    );
  }

  
  static toProject(projectResponse: ProjectResponse): Project {    
    return new Project(
      projectResponse.projectID,
      projectResponse.default,
      projectResponse.projectName,
      projectResponse.status ? projectResponse.status as Status : null,
      new Item(projectResponse.applicationID,projectResponse.applicationName),
      new Vehicle(
        0, 
        projectResponse.attributes?.manufacturer,
        projectResponse.attributes?.['vehicle-model'],
        (projectResponse.attributes?.['date-of-manufacture'] ? new Date(projectResponse.attributes?.['date-of-manufacture']) : null)
      ),
      projectResponse.tags,
      projectResponse.attributes.detail,
      this.vcuResponseToVcu(projectResponse.vcus),
      this.equipmentsResponseToEquipment(projectResponse.equipments),
      this.stringToImage(projectResponse.attributes?.photo,projectResponse.userID,projectResponse.projectID,'photo'),
      this.stringToImage(projectResponse.attributes?.primaryImage,projectResponse.userID,projectResponse.projectID,'primaryImage')[0],  
      null,
      this.projectSharingToShare(projectResponse.sharings),
      this.ProjectMappingToMaps(projectResponse.maps),
      this.ProjectLogsToLog(projectResponse.logs),
      '',
      null,
      projectResponse.userID,
      '',
      projectResponse.isSharing
    );
  }

  static toProjects(projectsResponse: ProjectResponse[]) : Project[] {
    if(!projectsResponse) return [];
  
    let projects: Project[] = []
    projectsResponse.map(projectResponse => projects.push(
      this.toProject(projectResponse)
    ));
    return projects;
  }

  imageToString(images: Image[]) : string {
    if(images && images.length > 0)
      return images.map(img => img.name).join(",");
    return '';
  }

  static stringToImage(str: string | null, userID: string, projectID: string, typeFile: string) : Image[] {
    if(!str || str === '') return [];
    let images: Image[] = []
    let imgsStr: string[] = str.split(',')
    let url = `${enviroment.BASE_URL_API}/files/user/${userID}/project/${projectID}/${typeFile}`;

    imgsStr.forEach((name, index) => {
        images.push(new Image(index, name.trim(), `${url}/${name}`));
    });

    return images;
  }

  setImageCard(project: Project, user: string) {

    if(project.primaryImage !== undefined && project.primaryImage !== null && project.primaryImage.name !== 'image12') {
      let url = `${enviroment.BASE_URL_API}/files/user/${user}/project/${project?.id}/photo/${project.primaryImage.name}`;
      let imageStyle = 'url("'+url+'")';
      return imageStyle;
    } else {
      let url = DEFAULT_IMAGE.href;
      let imageStyle = 'url("'+url+'")';
      return imageStyle;
    }
  }

  shareToProjectSharing(share: Share[]) : ProjectSharing[] {
    if(!share) return [];

    // let shares: ProjectSharing[] = []
    // share.map(sh => shares.push(
    //   {
    //     UserID: sh.id,
    //     Permission: sh.permission
    //   }
    // ));
    // return shares;
    return [];
  }

  static ProjectMappingToMaps(maps: ProjectMapping[]) : MapProject[] {
    if(!maps) return [];
  
    let mapsProject: MapProject[] = []
    maps.map(map => mapsProject.push(
      new MapProject(map.id,map.fileName,map.projectName,[], map.createdDate)
    ));
    return mapsProject;
  }

  vcuToVcuRequest(vcus: Vcu[]) : VcuRequest[] {
    if(!vcus) return [];

    let vcusReq: VcuRequest[] = []
    vcus.map(vcu => 
      vcusReq.push(VcuService.toVcuRequest(vcu))
    );
    return vcusReq;
    
  }

  static vcuResponseToVcu(vcusResp: VcuResponse[]) : Vcu[] {
    if(!vcusResp) return [];

    let vcus: Vcu[] = []
    vcusResp.map(vcu => 
      vcus.push(VcuService.toVcu(vcu))
    );
    return vcus;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  disableProject(userID: string, projectID: string) {
    return this.http.delete(this.projectUrl +'/' + userID + '/project/' + projectID, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError('disableProject'))
    );
  }

  static projectSharingToShare(share: ProjectSharing[]) : Share[] {
    if(!share) return [];
  
    let shares: Share[] = []
    share.map(sh => shares.push(
      new Share(sh.userId,sh.name,sh.email,sh.createdDate, sh.permission)
    ));
    return shares;
  }

  static ProjectLogsToLog(logs: ProjectLogs[]) : LogProject[] {
    if(!logs) return [];
  
    let logProjects: LogProject[] = []
    logs.map(sh => logProjects.push(
      //TODO BACKEND pedir para vir o Email e a data do Share
      new LogProject(sh.id,sh.fileName,sh.projectName,[], sh.createdDate)
    ));
    return logProjects;
  }

  CreateProjectSharing(projectID:string, userID:string, email: string) : Observable<CreateProjectSharingPostResp> {
    return this.http.post<CreateProjectSharingPostResp>(this.projectUrl +'/' + userID + '/project/' + projectID + '/sharing',{email:email}, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<CreateProjectSharingPostResp>(`FindUserEmail`))
    );
  }

  DeleteProjectMap(userID: string, projectID: string, mapID: string) {
    let url = `${this.projectUrl}/${userID}/project/${projectID}/map/${mapID}`;
    return this.http.delete(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError('DeleteProjectMap'))
    );
  }
  
  DeleteProjectLog(userID: string, projectID: string, logID: string) {
    let url = `${this.projectUrl}/${userID}/project/${projectID}/log/${logID}`;
    return this.http.delete(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError('DeleteProjectLog'))
    );
  }  
  
  DeleteProjectShare(userID: string, projectID: string, shareID: string) {
    let url = `${this.projectUrl}/${userID}/project/${projectID}/share/${shareID}`;
    return this.http.delete(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError('DeleteProjectShare'))
    );
  }

  static equipmentsResponseToEquipment(projectEquipments: ProjectEquipments[]) : Equipment[] {
    if(!projectEquipments) return [];

    let equipments: Equipment[] = []
    projectEquipments.map(projectEquipment => 
      equipments.push(new Equipment(projectEquipment.equipmentID,projectEquipment.equipmentName,projectEquipment.categoryID,projectEquipment.categoryName))
    );
    return equipments;
  }

  static userProjectToProject(projectResp: ProjectsFindUserVcusGetResp) : Project {
    return new Project(
      projectResp.projectID ?? '',
      projectResp.default ?? false,
      projectResp.projectName ?? '',
      projectResp.status ? projectResp.status as Status : null,
      projectResp.applicationID ? new Item(projectResp.applicationID) : null,
      null,
      projectResp.tags ?? [],
      '',
      [],
      [],
      projectResp.primaryImage ? [ new Image(0, projectResp.primaryImage)] : [],
      projectResp.attributes?.primaryImage ? new Image(0, projectResp.attributes?.primaryImage): null,
      null,
      projectResp.sharings ? this.projectSharingToShare(projectResp.sharings) : [],
      projectResp.maps ? this.ProjectMappingToMaps(projectResp.maps) : [],
      projectResp.logs ? this.ProjectLogsToLog(projectResp.logs) : [],
      '',
      projectResp.attributes ?? null
    );
  }

  static userProjectToProjects(projectsResp: ProjectsFindUserVcusGetResp[]) : Project[] {
    let projects: Project[] = [];
      if(projectsResp) {
        for(let proj of projectsResp) {
          projects.push(ProjectService.userProjectToProject(proj))
        }
      }
    return projects;
  }

  FindProjectsSharedSummary(userID: string) : Observable<ProjectSummaryResponse[]> {
    let url =`${this.projectUrl}/${userID}/project/shared`;     
    return this.http.get<ProjectSummaryResponse[]>(url, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<ProjectSummaryResponse[]>(`FindProjectsSharedSummary`))
    );
  }  
}
