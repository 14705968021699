export class PublicProjectResponse {
  projectName: string;
  attributes: ProjectAttributes;
  vcus: VcuResponse[];
  equipments? : string[];
  language?: string;
  path?: string;

  constructor(
      projectName: string,
      attributes: ProjectAttributes,
      vcus = new Array<VcuResponse>(),
      equipments = new Array<string>(),
      language?: string,
      path?: string
  ) {
      this.projectName = projectName || '';
      this.attributes = attributes;
      this.vcus = vcus;
      this.equipments = equipments;
      this.language = language;
      this.path = path;
  }
}

export interface ProjectAttributes {
  "date-of-manufacture": string;
  detail: string;
  manufacturer: string;
  photo: string;
  primaryImage: string;
  "vehicle-model": string;
}

export class VcuResponse {
  nickname?: string;
  model?: string;
  constructor(
      nickname: string,
      model?: string,
  ) {
      this.nickname = nickname;
      this.model = model;
  }
}

export class VcuAttributes {
  tags?: string;
  model?: number | string;
  reasonUnlink?: string;
  constructor(
    tags?: string[],
    model?: number | string,
    reasonUnlink?: string
  ) {
    this.tags = tags?.join(",");
    this.model = model;
    this.reasonUnlink = reasonUnlink;
  }
}


export enum LanguageEnum {
  PT_BR = 'pt-br',
  EN_US = 'en-us'
}

export class Project {
    name: string;
    vehicle: Vehicle | null;
    vcus: Vcu[];
    equipments: string[];
    images: Image[];
    primaryImage: Image | null;
    attributes?: ProjectAttributes | null;

    constructor(
        name = '',
        vehicle: Vehicle | null = null,
        vcus = new Array<Vcu>(),
        equipments = new Array<string>(),
        images = new Array<Image>(),
        primaryImage: Image | null = null,
        attributes: ProjectAttributes | null = null
    ) {
        this.name = name;
        this.vehicle = vehicle;
        this.vcus = vcus;
        this.equipments = equipments;
        this.images = images;
        this.primaryImage = primaryImage;
        this.attributes = attributes
    }
}

export class Vehicle {
  id: number;
  manufacturer: string;
  model: string;
  yearDate: Date | null;

  constructor(
      id = 0,
      manufacturer = '',
      model = '',
      yearDate: Date | null = null
  ) {
      this.id = id;
      this.manufacturer = manufacturer;
      this.model = model,
      this.yearDate = yearDate;
  }
}

export class Image {
  id?: number;
  name: string;
  href?: string;
  size?: number;

  constructor(
      id?: number, 
      name: string = '', 
      href?: string, 
      size?: number
  ) {
      this.id = id;
      this.name = name;
      this.href = href;
      this.size = size;
  }
}

export class Vcu {
    surname: string;
    model: string | null;

    constructor(
        surname: string = '',
        model: string | null = null,
    ) {
        this.surname = surname;
        this.model = model;
    }
}
