<app-dashboard-template>
    <div class="c-container">
      <p-tabMenu [model]="items" [ngStyle]="{'width': '100%'}" (onTabChange)="setActiveTab(activeTab)"></p-tabMenu>
      <div *ngIf="activeTab === 'data'" [ngStyle]="{'margin-left': '60px'}">
        <div class="overlay" *ngIf="isLoadingAccount">
          <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
        </div>
        <form class="c-form" [formGroup]="form" *ngIf="!isLoadingAccount" (ngSubmit)="onSubmit()">
            <div class="white">
                <!-- {{ form.value | json }} -->
            </div>
            <p-dropdown id="country"
                [options]="countryOptions" 
                formControlName="country" 
                name="country"
                (onChange)="setIsBrasil()"
                optionLabel="name" 
                placeholder="{{ 'user.account.country' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"/>
            <input id="name" 
                class="name" 
                formControlName="name" 
                name="name"
                type="text" 
                [placeholder]="placeHolderName" 
                pInputText />
            <!-- <input id="docNumber"
                class="docNumber"
                formControlName="docNumber"
                name="docNumber"
                type="text"
                placeholder="{{ 'user.account.docNumber' | transloco }}"
                (blur)="getMaskDocNumber()"
                maxlength="18"
                pInputText /> -->
            <input *ngIf="isBrasil"
                id="docNumber" 
                formControlName="docNumber" 
                name="docNumber"
                placeholder="CPF/CNPJ*"
                (blur)="getMaskDocNumber()"
                maxlength="18"
                [style]="{'width':'100%'}" 
                pInputText />
            <p-calendar *ngIf="showBirthDate && isBrasil"
                id="birthDate"
                placeholder="{{ 'user.account.birthDate' | transloco }}*"
                formControlName="birthDate"
                name="birthDate"
                [minDate]="minDate" 
                [maxDate]="maxDate" 
                dateFormat="dd/mm/yy"
                [showIcon]="true"
                [showOnFocus]="false"  
            />
            <p-calendar *ngIf="showBirthDate && !isBrasil"
                id="birthDate"
                placeholder="{{ 'user.account.birthDate' | transloco }}*"
                formControlName="birthDate"
                name="birthDate"
                [minDate]="minDate" 
                [maxDate]="maxDate" 
                dateFormat="mm/dd/yy"
                [showIcon]="true"
                [showOnFocus]="false"  
            />
            <div class="phone-container">
              <div class="country-selector">
                <p-dropdown 
                  [options]="countries" 
                  formControlName="phoneCountry"
                  optionLabel="name" 
                  placeholder="Pais" 
                  (onChange)="updateMask()"
                  >
                  <ng-template let-country pTemplate="item">
                    <div class="country-option">
                      <img [src]="country.flag" alt="{{ country.name }}" class="flag" />
                      {{ country.name }} ({{ country.dialCode }})
                    </div>
                  </ng-template>
                  <ng-template let-country pTemplate="selectedItem">
                    <div class="selected-country">
                      <img [src]="country.flag" alt="{{ country.name }}" class="flag" />
                      {{ country.dialCode }}
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
              <p-inputMask
                id="phone" 
                formControlName="phone" 
                name="phone"
                [mask]="currentMask"
                unmask="true"
                [style]="{'width':'100%'}"
                placeholder="{{ 'user.account.phone' | transloco }}*"
                 />
            </div>
            <input class="email" 
                type="email"
                id="email" name="email"
                formControlName="email" 
                placeholder="{{ 'user.account.email' | transloco }}" 
                pInputText />
            <p-selectButton id="language"
                name="language"
                formControlName="language" 
                [options]="languageOptions" 
                optionLabel="name"
                (click)="fixLanguage()">
                <ng-template let-item pTemplate>
                    <div [class]="item.class"></div>
                </ng-template>
            </p-selectButton>
            <div class="c-row">
                <p-inputSwitch id="checkNewsletter"
                    formControlName="checkNewsletter" />
                <label id="labelCheckNewsletter" for="checkNewsletter">
                    {{ 'user.account.checkNewsletter' | transloco }}
                </label>
            </div>
            <div class="c-row">
              <p-inputSwitch 
                  id="mfaEnabled" 
                  [(ngModel)]="mfaEnabled" 
                  [ngModelOptions]="{ standalone: true }">
              </p-inputSwitch>
              <label id="labelMfaEnabled" for="mfaEnabled">
                {{ 'user.account.mfaEnabled' | transloco }}
              </label>
            </div>
            <div class="c-row">
              <p-button
                  type="button"
                  severity="danger"
                  [raised]="true"
                  label="{{'user.account.inactive' | transloco}}"
                  (onClick)="confirmInactive()">
              </p-button>
            </div>
            <p-dropdown *ngIf="f.inactive.value"
                id="inactiveReason"
                [options]="inactiveReasonOptions" 
                formControlName="inactiveReason" 
                name="inactiveReason"
                optionLabel="description" 
                placeholder="{{ 'user.account.inactiveReason' | transloco }}"
                emptyMessage="{{ 'noResult' | transloco }}"/>
            
            <!-- <p-messages id="messages"
                [style]="{'font-size':'15px'}"/> -->
            <p-toast key="toastAccount" />

              <div class="btns">
                  <p-button id="btnBackLogin"
                      severity="secondary"
                      [raised]="true"
                      [loading]="backBtnLoading"
                      (click)="backPage()"
                      label="{{'user.account.btnBack' | transloco}}">
                  </p-button>
                  <p-button
                      type="submit"
                      severity="danger"
                      [raised]="true"
                      [loading]="submitBtnLoading"
                      label="{{'user.account.btnSave' | transloco}}">
                  </p-button>
              </div>
              <div class="c-row footerMsg" style="padding-bottom: 50px;">
                  {{'user.account.footerMsg' | transloco}}
              </div>
          </form>
        </div>
        <div *ngIf="activeTab === 'trash'" [ngStyle]="{'width': '100%'}">
          <br>
          <div class="trash-container">
            <h1 class="title">{{ 'user.trash.header' | transloco }}</h1>
            <p-toast key="trashtAccount" />
            <p-table
              class="custom-table"
              #dtTrash
              [value]="itensTrash"
              dataKey="name"
              [totalRecords]="itensTrash.length"
              [sortField]="'name'"
              [loading]="loadingTrash"
              responsiveLayout="scroll"
              [rows]="10"
              [paginator]="true"
              [rowsPerPageOptions]="[10, 20, 30]"
              [showCurrentPageReport]="true"
              currentPageReportTemplate="Exibindo {first} de {last} de {totalRecords} registros"
              [rowHover]="true"
              [globalFilterFields]="[
                  'name',
                  'type',
                  'deletionDate'
              ]"
            >
            <ng-template pTemplate="caption">
              <div
                  class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
              >
                  <span
                      class="block mt-2 md:mt-0 p-input-icon-left hide-item"
                  >
                      <i class="pi pi-search"></i>
                      <input
                          pInputText
                          [(ngModel)]="searchValue"
                          [ngModelOptions]="{
                              standalone: true
                          }"
                          id="search"
                          name="search"
                          type="text"
                          (input)="
                              onGlobalFilter(
                                  dtTrash,
                                  $event
                              )
                          "
                          (keydown.enter)="
                              onEnterPress($event)
                          "
                          placeholder="{{ 'user.trash.search' | transloco }}"
                          class="w-full sm:w-auto"
                      />
                  </span>
              </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="17" class="text-center">
                    {{ 'user.trash.noResult' | transloco }}
                  </td>
              </tr>
            </ng-template>
            <ng-template let-item pTemplate="header">
              <tr>
                  <th
                      style="min-width: 8rem"
                      pSortableColumn="name"
                  >
                      <div
                          class="flex justify-content-between align-items-center"
                      >
                      {{ 'user.trash.name' | transloco }}
                          <p-sortIcon
                              field="name"
                          ></p-sortIcon>
                      </div>
                  </th> 
                  <th
                      style="min-width: 8rem"
                      pSortableColumn="type"
                  >
                      <div
                          class="flex justify-content-between align-items-center"
                      >
                      {{ 'user.trash.type' | transloco }}
                          <p-sortIcon
                              field="type"
                          ></p-sortIcon>
                      </div>
                  </th>
                  <th
                      style="min-width: 8rem"
                      pSortableColumn="deletionDate"
                  >
                      <div
                          class="flex justify-content-between align-items-center"
                      >
                      {{ 'user.trash.deletionDate' | transloco }}
                          <p-sortIcon
                              field="deletionDate"
                          ></p-sortIcon>
                      </div>
                  </th>
                  <th
                      style="min-width: 8rem"
                      pSortableColumn="Acao"
                  >
                      <div
                          class="flex justify-content-between align-items-center"
                      >
                      {{ 'user.trash.action' | transloco }}
                      </div>
                  </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr
                  style="cursor: pointer"
                  [pSelectableRow]="item"
              >
                  <td>{{ item.name }}</td>
                  <td>
                      {{ item.type }}
                  </td>  
                  <td>
                    {{
                        item.deletionDate
                            | dynamicDateHour: currentLocale
                    }}
                  </td>
                  <td>
                    <button
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-undo"
                        class="p-button-rounded p-button-text"
                        (click)="restore(item)"
                    ></button>
                    <button *ngIf="showTrash(item.type)"
                        pButton
                        pRipple
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text"
                        (click)="trashPermanently(item)"
                    ></button>                    
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>   
      </div>
    </div>
    <p-confirmDialog></p-confirmDialog>   
</app-dashboard-template>
