import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './module/user/login/login.component';
import { CreateAccountComponent } from './module/user/create-account/create-account.component';
import { AuthGuard } from './service/auth.service';
import { DashboardComponent } from './module/dashboard/dashboard/dashboard.component';
import { ActivateAccountComponent } from './module/user/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './module/user/forgot-password/forgot-password.component';
import { ProjectComponent } from './module/project/project/project.component';
import { VcuComponent } from './module/vcu/vcu/vcu.component';
import { AccountComponent } from './module/user/account/account.component';
import { VcuManagementComponent } from './module/vcu/vcu-management/vcu-management.component';
import { PublicProjectComponent } from './public/public-project/public-project.component';
import { MfaLoginComponent } from './module/user/mfa-login/mfa-login.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'create-account', component: CreateAccountComponent},
  { path: 'activate-account', component: ActivateAccountComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'project/public/:user/:project', component: PublicProjectComponent},
  { path: 'mfa-login', component: MfaLoginComponent},


  { path: 'dashboard', component: DashboardComponent, canActivate : [AuthGuard]}, 
  { path: 'vcu-management', component: VcuManagementComponent, canActivate : [AuthGuard]},
  { path: 'project/:id', component: ProjectComponent, canActivate : [AuthGuard]},
  { path: 'vcu/:id', component: VcuComponent, canActivate : [AuthGuard]},
  { path: 'profile/:id', component: AccountComponent, canActivate : [AuthGuard]},
  
  { path: '', redirectTo:'/login', pathMatch:'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
