import { Injectable } from '@angular/core';
import { Vcu } from '../model/form/vcu';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { enviroment } from 'src/environments/environment';
import { VcuRequest } from '../model/request/vcu-request';
import { VcuResponse } from '../model/response/vcu-response';
import { Item } from '../model/Item';
import { Status } from '../model/enum/status';
import { FindUserVcusGetResp, ProjectsFindUserVcusGetResp } from '../model/response/find-user-vcus-get-resp';
import { ProjectService } from './project.service';
import { Image } from 'src/app/model/Image';

@Injectable({providedIn: 'root'})
export class VcuService {

  httpOptions: {};
  vcuRequest: VcuRequest;
  userID: string = localStorage.getItem('idUser') ?? '';
  projectID: string = '';
  vcuID: string = '';
  vcuUrl: string = enviroment.BASE_URL_API + '/user';;

  constructor(private http: HttpClient) {
    this.httpOptions = {};
    this.vcuRequest = new VcuRequest();
  }


  findVcu():Observable<VcuResponse> {
    return this.http.get<VcuResponse>(enviroment.BASE_URL_API 
      + '/user/' + this.userID 
      + '/project/' + this.projectID 
      + '/vcu/' + this.vcuID , this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<VcuResponse>('findVcu'))
    );
  }

  getVcu():Observable<FindUserVcusGetResp[]> {
    return this.http.get<FindUserVcusGetResp[]>(enviroment.BASE_URL_API 
      + '/user/' + this.userID 
      + '/vcu/' + this.vcuID , this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<FindUserVcusGetResp[]>('getVcu'))
    );
  }

  createVcu(vcu: Vcu) : Observable<VcuResponse> {
    let vcuRequest = VcuService.toVcuRequest(vcu);  
    let url = `${this.vcuUrl}/${this.userID}/vcu`
    return this.http.post<VcuResponse>(url, vcuRequest, this.httpOptions).pipe(tap());
  }

  updateVcu(vcu: Vcu) : Observable<VcuResponse> {
    let vcuRequest = VcuService.toVcuRequest(vcu);  

    return this.http.put<VcuResponse>(this.vcuUrl + '/' + this.userID + '/vcu/' + this.vcuID, vcuRequest, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<VcuResponse>('updateVcu'))
    );
  }

  updateVcuNoProject(vcu: Vcu) : Observable<VcuResponse> {
    let vcuRequest = VcuService.toVcuRequest(vcu);  

    return this.http.put<VcuResponse>(this.vcuUrl + '/' + this.userID + '/vcu/' + this.vcuID, vcuRequest, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<VcuResponse>('updateVcuNoProject'))
    );
  }

  deleteVcu() : Observable<VcuResponse> {
    return this.http.delete<VcuResponse>(this.vcuUrl + '/' + this.userID + '/project/' + this.projectID + '/vcu/' + this.vcuID, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<VcuResponse>('deleteVcu'))
    );
  }

  deleteVcuNoProject() : Observable<VcuResponse> {
    return this.http.delete<VcuResponse>(this.vcuUrl + '/' + this.userID + '/vcu/' + this.vcuID, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<VcuResponse>('deleteVcu'))
    );
  }

  linkVcuInProject() {
    return this.http.put<{}>(this.vcuUrl + '/' + this.userID + '/project/' + this.projectID + '/vcu/' + this.vcuID, {}, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<{}>('addVcu'))
    );
  }

  unlinkVcuInProject() {
    return this.http.delete<{}>(this.vcuUrl + '/' + this.userID + '/project/' + this.projectID + '/vcu/' + this.vcuID, this.httpOptions).pipe(
      tap(),
      catchError(this.handleError<{}>('addVcu'))
    );
  }
  vcuImage(idAux: number , vcu: Vcu): Observable<Image> {
    let imgname = vcu.model?.name.replace(/\s+/g, '').toUpperCase() ?? 'default';
    let imageVCU = new Image(idAux, `${imgname}`, `${enviroment.BASE_URL_API_IMAGE_VCU}/` + imgname + '.png');
    return of(imageVCU);
  }

  static toVcuRequest(vcu: Vcu) {
    //TODO converter campos e montar a REQUEST do backend
    return new VcuRequest(
      vcu.serialNumber, 
      vcu.surname, 
      vcu.family?.id, 
      vcu.model?.id, 
      vcu.invoice, 
      vcu.purchaseDate?.toISOString(),
      vcu.tags,
      vcu.vcuAttributes?.reasonUnlink);
  }

  static toVcu(vcu: VcuResponse) : Vcu {
    if(vcu) {
      return new Vcu(
        vcu.id,
        null,
        vcu.nickname,
        new Item(vcu.familyID, vcu.familyDescription),
        new Item(vcu.modelID, vcu.modelDescription),
        vcu.serialNumber,
        new Item(vcu.resellerID, vcu.resellerDescription),
        vcu.invoiceNumber,
        (vcu.purchaseDate ? new Date(vcu.purchaseDate) : null),
        (vcu.vcuAttributes ? vcu.vcuAttributes.tags?.split(",") ?? [] : []),
        (vcu.modifiedDate ? new Date(vcu.modifiedDate) : null),
        (vcu.status ? vcu.status as Status : null),
        (vcu.createdDate ? new Date(vcu.createdDate) : null),
        (vcu.lastAccess ? new Date(vcu.lastAccess) : null),
        vcu.vcuAttributes ?? null,
        ProjectService.toProjects(vcu.projects)
      );
    }
    return new Vcu();
  }

  static userVcuToVcu(vcu: FindUserVcusGetResp) : Vcu {
    return new Vcu(
      vcu.id ?? '',
      null,
      vcu.nickname ?? '',
      new Item(vcu.family?.familyID, vcu.family?.description),
      new Item(vcu.model?.id, vcu.model?.name ),
      vcu.serial ?? '',
      new Item(vcu.reseller?.resellerID, vcu.reseller?.description),
      vcu.invoiceNumber ?? '',
      (vcu.purchaseDate ? new Date(vcu.purchaseDate) : null),
      vcu.vcuAttributes?.tags?.split(',') ?? [],
      (vcu.createdDate ? new Date(vcu.createdDate) : null),
      (vcu.status ? vcu.status as Status : null),
      (vcu.createdDate ? new Date(vcu.createdDate) : null),
      (vcu.lastAccess ? new Date(vcu.lastAccess) : null),
      vcu.vcuAttributes ?? null,
      vcu.projects ? ProjectService.userProjectToProjects(vcu.projects) : []
    );
  }

  static userVcuToVcus(vcusResp: FindUserVcusGetResp[]) : Vcu[] {
    let vcus: Vcu[] = [];
    vcusResp.forEach(resp => vcus.push(VcuService.userVcuToVcu(resp)))
    return vcus;
  }

  static userVcuToProjects(vcusResp: FindUserVcusGetResp) : ProjectsFindUserVcusGetResp[] {
    let projects: ProjectsFindUserVcusGetResp[] = [];
      if(vcusResp.projects) {
        for(let proj of vcusResp.projects) {
          projects.push(new ProjectsFindUserVcusGetResp(proj.projectID, proj.projectName))
        }
      }
    return projects;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  setUserID(userID: string){
    this.userID = userID;
  }

  setProjectID(projectID: string){
    this.projectID = projectID;
  }

  getProjectID() : string {
    return this.projectID;
  }

  setVcuID(vcuID: string){
    this.vcuID = vcuID;
  }
}
