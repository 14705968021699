import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Account } from '../model/form/account';
import { CognitoService } from './cognito.service';
import { User } from '../model/user';
import { CognitoException } from '../model/enum/cognito-exception';
import { DocType } from '../model/doc-type';
import { LanguageEnum } from '../model/enum/language-enum';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User = new User();
  private refreshTimer: any;

  constructor(private router: Router, private cognitoService: CognitoService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
  
      if (!token) return false;
  
      const isTokenValid = await this.verifyToken(token);
  
      if (isTokenValid) {
        const user = await this.getUser(token);
        if (user?.Username) {
          localStorage.setItem('nameUser', user.Username);
        }
        return true;
      }
  
      if (refreshToken) {
        const refreshStatus = await this.refreshAuth();
        if (refreshStatus === CognitoException.SUCCESS) {
          return true;
        }
      }
      return false;
  
    } catch (error) {
      console.error('Erro ao verificar token ou realizar refresh:', error);
      return false;
    }
  }
  
  async signUp(account: Account, language: string | null) {
    account.email.trim();
    if(account.docNumber){
      account.docNumber = this.removeMask(account.docNumber);
      account.docType = (account.docNumber.length === 11 ? DocType.CPF : DocType.CNPJ);
    }else{
      account.docNumber = '0'.repeat(11);
      account.docType = DocType.CPF;      
    }
    account.language = (language === null ? LanguageEnum.EN_US : language);
    return this.cognitoService.signUp(account);
  }

  async confirmSignUp(email: string, code: string) {
    this.user = new User();
    this.user.email = email.trim();
    this.user.code = code.trim();
    return this.cognitoService.confirmSignup(this.user);
  }

  async confirmLoginMFA(session: string, username: string, code: string): Promise<boolean> {
    if (!session) return false; 
    
    try {
      // const result = await this.cognitoService.respondToAuthChallenge(session, username, code);
  
      // if (result.AuthenticationResult) {
      //   if (result.AuthenticationResult.RefreshToken) 
      //     localStorage.setItem('refreshToken', result.AuthenticationResult.RefreshToken);
      //   if (result.AuthenticationResult.IdToken) 
      //     localStorage.setItem('idToken', result.AuthenticationResult.IdToken);
      //   if (result.AuthenticationResult.AccessToken)
      //     localStorage.setItem('token', result.AuthenticationResult.AccessToken);
      //   return true;
      // }
      return false;
    } catch (error) {
      console.error('Erro ao verificar código MFA:', error);
      return false;
    }
  }
  
  async initiateAuth(email: string, password: string): Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    this.user.password = password;
  
    try {
      const authResult = await this.cognitoService.initiateAuth(this.user);
      this.startTokenRefresh();
  
      return authResult;
    } catch (error) {
      console.error('Erro ao autenticar:', error);
      throw error;
    }
  }
  

  async refreshAuth(): Promise<string> {
    const refreshToken = localStorage.getItem('refreshToken');
    const username = localStorage.getItem('nameUser') || '';
    const userEmail = localStorage.getItem('email') || '';
  
    if (!refreshToken) {
      console.error('RefreshToken não encontrado no localStorage.');
      return Promise.reject('RefreshToken ausente.');
    }
  
    const user = new User();
    user.refreshToken = refreshToken;
    user.email = userEmail;
    user.usernameAWS = username;
  
    try {
      const newToken = await this.cognitoService.refreshAuth(refreshToken, user);
  
      if (!newToken) {
        console.error('Token vazio recebido ao tentar renovar.');
        return Promise.reject('Token vazio recebido ao tentar renovar.');
      }
      await this.getUser(newToken);
  
      localStorage.setItem('token', newToken);
      this.startTokenRefresh();

      return CognitoException.SUCCESS;
  
    } catch (error) {
      console.error('Erro ao renovar o token:', error);
      return Promise.reject('Erro ao renovar o token.');
    }
  }
  
  private decodeToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return null;
    }
  }

  public startTokenRefresh() {
    const token = localStorage.getItem('token');

    if (!token) {
      console.warn('Nenhum token encontrado para configurar o refresh automático.');
      return;
    }

    const decodedToken = this.decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      console.warn('Token inválido ou sem campo de expiração.');
      return;
    }

    const expirationTime = decodedToken.exp * 1000;
    const refreshTime = expirationTime - Date.now() - 60000;

    if (refreshTime <= 0) {
      console.warn('Token já está expirado ou próximo do vencimento.');
      return;
    }

    this.refreshTimer = setTimeout(async () => {
      try {
        await this.refreshAuth();
        this.startTokenRefresh();
      } catch (error) {
        console.error('Erro ao renovar o token automaticamente:', error);
      }
    }, refreshTime);
  }

  public stopTokenRefresh() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      console.log('Timer de refresh cancelado.');
    }
  }

  
  async forgotPassword(email: string, name: string | null, language: string | null) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    if(!name) {
      if(LanguageEnum.EN_US === language)
        this.user.name = 'User';
      else
        this.user.name = 'Usuário';
    } else {
      this.user.name = name;
    }
    this.user.language = (language ? language : LanguageEnum.PT_BR);
    return this.cognitoService.forgotPassword(this.user);
  }

  async confirmForgotPassword(email: string, password: string, code: string) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    this.user.password = password;
    this.user.code = code.trim();
    return this.cognitoService.confirmForgotPassword(this.user);
  }

  async resendConfirmationCode(email: string, name: string | null, language: string | null, origin: string | null) : Promise<any> {
    this.user = new User();
    this.user.email = email.trim();
    if(!name) {
      if(LanguageEnum.EN_US === language)
        this.user.name = 'User';
      else
        this.user.name = 'Usuário';
    } else {
      this.user.name = name;
    }
    this.user.language = (language ? language : LanguageEnum.PT_BR);
    origin = (origin ? origin : 'create');
    return this.cognitoService.resendConfirmationCode(this.user, origin);
  }

  async getUser(token: string) {
    return this.cognitoService.getUser(token);
  }

  async setUserMFA(token: string, mfaEnabled: boolean) {
    return this.cognitoService.setUserMFA(token, mfaEnabled);
  }

  async updateUserAttibutes(token: string, mfaEnabled: boolean) {
    return this.cognitoService.updateUserAttibutes(token, mfaEnabled);
  }

  async verifyToken(token: string) : Promise<boolean> {
    return this.cognitoService.verifyToken(token);
  }

  async revokeToken(token: string) {
    this.user = new User();
    this.user.token = token;
    this.cognitoService.revokeAuth(this.user);
  }

  async respondToChallenge(session: string, username: string, code: string) {
    return this.cognitoService.respondToChallenge(session, username, code);
  }

  async logout() : Promise<boolean> {
    let token = localStorage.getItem('refreshToken');
    if(token) {
      await this.revokeToken(token);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email-activate-account');
    localStorage.removeItem('userID');
    localStorage.removeItem('idUser');
    localStorage.removeItem('nameUser');
    localStorage.removeItem('language');
    localStorage.removeItem('session');
    localStorage.removeItem('email');
    localStorage.removeItem('challengeName');
    this.router.navigate(['/login']);    
    this.stopTokenRefresh();
    return true;
  }

  private removeMask(str: string) : string {
    str = str.replaceAll(".","");
    str = str.replaceAll("-","");
    str = str.replaceAll("/","");
    return str;
  }
}



export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthService).canActivate(next, state);
}