<div class="background" [style.display]="isDivCreate">
  <div class="header">
    <a routerLink="{{ loginUrl }}">
      <div class="logo-write"></div>
    </a>
    <strong class="header-title">{{ 'user.confirmLoginMfa.header' | transloco }}</strong>
    <div class="others">
      <p-selectButton 
        [(ngModel)]="language"
        [options]="languageOptions" 
        optionLabel="name"
        optionDisabled="disabled"
        (onOptionClick)="fixLanguage($event)"
      >
        <ng-template let-item pTemplate>
          <div [class]="item.class"></div>
        </ng-template>
      </p-selectButton>
    </div>
  </div>
  
  <div class="container">
      <!-- <div class="title">
          <h1>{{ 'user.confirmLoginMfa.title' | transloco }}</h1>
          <h1>{{ 'user.confirmLoginMfa.title2' | transloco }}</h1>
      </div> -->
      <p>{{ 'user.confirmLoginMfa.title' | transloco }}</p>
      <p>{{ 'user.confirmLoginMfa.title2' | transloco }}</p>
      <form class="sendCode" [formGroup]="form" (ngSubmit)="onSubmit()">
          <!-- {{ form.value | json }} -->
          <p-inputOtp 
              formControlName="code"
              [integerOnly]="true" 
              [length]="6" />
          <p-messages id="messages"
              [(value)]="messagesCode" 
              [enableService]="false" 
              [style]="{'font-size':'15px'}"/>
          <p-button
              type="submit"
              severity="danger"
              [raised]="true"
              label="{{ 'user.confirmLoginMfa.btnSend' | transloco }}">
          </p-button>
      </form>
      <div class="row">
          {{ 'user.confirmLoginMfa.resend' | transloco }} <a [routerLink]="[]" (click)="redirect()">{{ 'user.confirmLoginMfa.resend2' | transloco }}</a>
      </div>
  </div>
  <p-toast key="mfalogin" />
</div>

<!-- <div class="background" [style.display]="isDivActivated">
  <div class="header">
      <a routerLink="{{ loginUrl }}">
          <div class="logo-write"></div>
      </a>
      <strong>{{ 'user.activateAccount.header' | transloco }}</strong>
  </div>
  <div class="container">
      <div class="title">
          <h1>{{ 'user.activateAccount.title3' | transloco }}</h1>
      </div>
      <p>{{ 'user.activateAccount.body4' | transloco }}
          <a id="redirect" [routerLink]="[]" (click)="redirect()">
              {{ 'user.activateAccount.body6' | transloco }}
          </a>
      </p>
  </div>
</div> -->

<div class="background" [style.display]="isDivExpired">
  <div class="header">
      <a routerLink="{{ loginUrl }}">
          <div class="logo-write"></div>
      </a>
      <strong>{{ 'user.confirmLoginMfa.header' | transloco }}</strong>
  </div>
  <div class="container">
      <div class="title">
          <h1>{{ 'user.confirmLoginMfa.title4' | transloco }}</h1>
      </div>
      <p>{{ 'user.confirmLoginMfa.body5' | transloco }}
          <a id="expired" [routerLink]="[]" (click)="expired()">
              {{ 'user.confirmLoginMfa.body6' | transloco }}
          </a>
      </p>
  </div>
</div>
