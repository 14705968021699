import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';
import { PublicService } from './service.service';
import { PublicProjectComponent } from './public-project/public-project.component';


@NgModule({
  declarations: [
    PublicProjectComponent
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    CarouselModule,
    ImageModule,
  ],
  providers: [
    PublicService // Registrar o serviço aqui
  ]
  
})
export class PublicModule { }
