import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { enviroment } from 'src/environments/environment';
import { catchError, Observable, of, tap } from 'rxjs';
import { PublicProjectResponse  } from './public-project-response';



@Injectable({providedIn: 'root'})
export class PublicService {
  publicUrl: string;

  constructor(private http: HttpClient) {
    this.publicUrl = enviroment.BASE_URL_API + '/global';
  }

  getPublicProject(user: string, project: string): Observable<PublicProjectResponse> {
    const url = `${this.publicUrl}/user/${user}/project/${project}`;
    return this.http.get<PublicProjectResponse>(url).pipe(
      tap(),
      catchError(this.handleError<PublicProjectResponse>(`findAllProjectSummary`))
    );
  }

  getCountry() {
    return this.http.get('https://ipapi.co/json/'); // API de geolocalização
}
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
