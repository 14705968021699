import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { Vcu } from 'src/app/model/form/vcu';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { ToolbarModule } from 'primeng/toolbar';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/service/account.service';

@Component({
  selector: 'app-dashboard-template',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoRootModule,
    HttpClientModule,
    ToolbarModule,
    AvatarModule,
    MenubarModule,
    SidebarModule,
    ButtonModule],
  templateUrl: './dashboard-template.component.html',
  styleUrl: './dashboard-template.component.css'
})
export class DashboardTemplateComponent {

  items: MenuItem[] | undefined;
  vcus: Vcu[];
  sidebarMenuVisible: boolean;
  sidebarProfileVisible: boolean;
  profileUrl: string;
  dashboardUrl: string;
  vcuUrl: string;
  username: string;

  constructor(private authService: AuthService,
    private router: Router,
    private translocoService: TranslocoService,
    private accountService: AccountService) {

    this.profileUrl = '/profile';
    this.dashboardUrl = '/dashboard';
    this.vcuUrl = '/vcu-management';
    let name = localStorage.getItem('nameUser');
    this.username = (name ? name : 'User');
    this.vcus = [];
    this.sidebarMenuVisible = false;
    this.sidebarProfileVisible = false;
    this.translocoService.selectTranslateObject('dashboard.header').subscribe(value => {
      this.items = [
      {
        label: value?.garage,
        command: () => {
          this.pageGarage();
        }
      },
      {
        label: value?.sharedGarage,
        command: () => {
          this.pageSharedGarage();
        }
      },
      {
        label: value?.vcu,
        command: () => {
          this.pageVCU();
        }
      }];
    });
  }

  ngOnInit(): void {
    let token = localStorage.getItem('token');
    if(token) this.accountService.findUserActive().subscribe(
      data =>{
        this.username = (data.name ? data.name : 'User');      
      });
  }

  pageGarage() {
    this.router.navigate([this.dashboardUrl], { fragment: "garage" });
  }

  pageSharedGarage() {
    this.router.navigate([this.dashboardUrl], { fragment: "sharedGarage" });
  }
  
  pageVCU() {
    this.router.navigate([this.vcuUrl]);
  }

  myProfile(id: string) {
    this.router.navigate([this.profileUrl + '/' + id]);
  }


  async logout() {
    await this.authService.logout();
  }
}
