<p-dialog
  id="modal-register-project"
  name="modal-register-project"
  header="{{ 'project.register.header' | transloco }}"
  [modal]="true"
  [draggable]="false"
  [(visible)]="showDialog"
  (onHide)="closeModal()"
  [dismissableMask]="true"
  [style]="{'border-radius':'130px'}">

  <div class="modal-content">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- {{ form.value | json }} -->

        <p-steps [activeIndex]="active" [model]="stepItems" [readonly]="true" />
    
        <div class="modal-content-page1" [(style.display)]="modalpage[0]">
            <div class="row">
              <input
                  type="text" 
                  id="name" name="name"
                  formControlName="name"
                  placeholder="{{ 'project.register.name' | transloco }}*"
                  pInputText >
              <p-dropdown id="application"
                  [options]="applicationOptions" 
                  formControlName="application" 
                  name="application"
                  optionLabel="name" 
                  placeholder="{{ 'project.register.application' | transloco }}*"
                  emptyMessage="{{ 'noResult' | transloco }}"/>
            </div>
            <div class="row">
              <input
                  type="text" 
                  id="manufacturer" name="manufacturer"
                  formControlName="manufacturer"
                  placeholder="{{ 'project.register.manufacturer' | transloco }}"
                  pInputText >
              <input
                  type="text" 
                  id="model" name="model"
                  formControlName="model"
                  placeholder="{{ 'project.register.model' | transloco }}"
                  pInputText >
              <p-calendar 
                id="yearDate" name="yearDate"
                formControlName="yearDate"
                placeholder="{{ 'project.register.yearDate' | transloco }}"
                view="year" 
                dateFormat="yy" />
            </div>
            <div class="row">
                <div class="row row-tag">
                    <p-chips 
                        id="tags" 
                        name="tags" 
                        placeholder="{{ 'project.register.tags' | transloco }}"
                        formControlName="tags" 
                        separator="," />
                    <label id="tags-label" for="tags">
                        {{ 'project.register.tagsLabel' | transloco }}
                    </label>
                </div>
                <div class="row-favorite">
                    <p-inputSwitch id="favorite" formControlName="favorite"/>
                    <span>Favorito</span>
                </div>
            </div>
            <div class="row">
              <textarea 
                id="detail"
                name="detail"
                rows="5" 
                cols="30" 
                placeholder="{{ 'project.register.detail' | transloco }}"
                pInputTextarea 
                formControlName="detail">
              </textarea>
            </div>
            <div class="row-btn">
              <p-button
                id="codeBtnActive"
                name="codeBtnActive"
                label="{{ 'project.register.nextBtn' | transloco }}"
                (click)="modalGoPage2()"
                severity="danger"
                [raised]="true"/>
            </div>
        </div>
        <div class="modal-content-page2" [(style.display)]="modalpage[1]">
            <div class="centered-spinner" *ngIf="isLoadingEquipments">
                <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
            </div>
            <ul class="ul-generic" *ngIf="!isLoadingEquipments">
              <li class="li-row" *ngFor="let generic of getGenericEquipments(); let i = index" formArrayName="genericEquipments">
                <ng-container [formGroupName]="i">
                    <div class="li-number">{{i}}</div>
                    <div class="row-input" *ngIf="getGenericType(generic.value) === 'vcu'">
                      <p-dropdown id="vcus" name="vcus"
                          [options]="vcusOptions" 
                          formControlName="vcu"
                          optionLabel="name"
                          appendTo="body"
                          placeholder="{{ 'project.register.vcu' | transloco }}*"
                          emptyMessage="{{ 'project.register.emptyMessageVcu' | transloco }}"/>
                    </div>
                    <div class="row-input" *ngIf="getGenericType(generic.value) === 'equipment'">
                      <p-dropdown id="categories" name="categories"
                          [options]="categoriesOptions" 
                          formControlName="category" 
                          optionLabel="name" 
                          appendTo="body"
                          (onChange)="FindEquipments($event.value.id, i)"
                          placeholder="{{ 'project.register.category' | transloco }}*"
                          emptyMessage="{{ 'noResult' | transloco }}"/>
                      <p-dropdown id="products" name="products"
                          [options]="productsOptions[i]"
                          formControlName="product" 
                          optionLabel="name" 
                          appendTo="body"
                          placeholder="{{ 'project.register.product' | transloco }}*"
                          emptyMessage="{{ 'project.register.emptyMessageProduct' | transloco }}"/>
                    </div>
                    <p-button icon="pi pi-trash" 
                        id="removeGenericBtn" name="removeGenericBtn"
                        severity="secondary"
                        size="small"
                        (click)="removeGenericEquipment(i)" />
                </ng-container>
              </li>
            </ul>
            <div class="cards">
              <div class="card-plus">
                <a [routerLink]="[]" (click)="addGenericEquipment('vcu')">+ {{ 'project.register.newVcu' | transloco }}</a>
              </div>
              <div class="card-plus">
                <a [routerLink]="[]" (click)="addGenericEquipment('equipment')">+ {{ 'project.register.newEquipment' | transloco }}</a>
              </div>
            </div>
            <div class="row-btn">
              <p-button
                id="btnBack"
                name="btnBack"
                label="{{ 'project.register.backBtn' | transloco }}"
                severity="secondary"
                [raised]="true"
                (click)="backPage(1)"/>
              <p-button
                id="btnActive"
                name="btnActive"
                label="{{ 'project.register.nextBtn' | transloco }}"
                (click)="modalGoPage3()"
                severity="danger"
                [raised]="true"/>
            </div>
        </div>
        <div class="modal-content-page3" [(style.display)]="modalpage[2]">
            <div class="upload">
                <p-fileUpload #fileUpload id="upload" name="upload" (onUpload)="onSelectFile($event)" chooseIcon="pi pi-upload"
                    chooseLabel="{{ 'project.register.upload.add' | transloco }}"
                    url="https://www.primefaces.org/cdn/api/upload.php"
                    invalidFileSizeMessageSummary="{{ 'project.register.upload.sizeSummary' | transloco }}"
                    invalidFileSizeMessageDetail="{{ 'project.register.upload.sizeDetail' | transloco }}"
                    invalidFileTypeMessageSummary="{{ 'project.register.upload.typeSummary' | transloco }}"
                    invalidFileTypeMessageDetail="{{ 'project.register.upload.typeDetail' | transloco }}"
                    invalidFileLimitMessageSummary="{{ 'project.register.upload.limitSummary' | transloco }}"
                    invalidFileLimitMessageDetail="{{ 'project.register.upload.limitDetail' | transloco }}" [multiple]="true"
                    accept="image/*" maxFileSize="5242880" [auto]="true">
                    <ng-template pTemplate="content">
                        <p-table *ngIf="project && project.images.length;" #dtImage [value]="project.images" dataKey="id" [rows]="5"
                            [loading]="loadingImage" [tableStyle]="{ 'min-width': '25rem', 'width':'100%' }">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:15%">
                                        <div class="center-column">
                                            {{ 'project.register.upload.primaryPhoto' | transloco }}
                                        </div>
                                    </th>
                                    <th style="width:35%">
                                        <div class="center-column">
                                            {{ 'project.register.upload.image' | transloco }}
                                        </div>
                                    </th>
                                    <th pSortableColumn="name" style="width:25%">
                                        {{ 'project.register.upload.name' | transloco }} <p-sortIcon field="date" />
                                    </th>
                                    <th style="width:15%">
                                        {{ 'project.register.upload.size' | transloco }}
                                    </th>
                                    <th style="width:10%">
                                        <div class="center-column">
                                            {{ 'project.register.upload.remove' | transloco }}
                                        </div>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-image>
                                <tr>
                                    <td>
                                        <div class="center-column">
                                            <input type="radio" name="project.primaryImage" [value]="image"
                                                [(ngModel)]="project.primaryImage" [ngModelOptions]="{standalone: true}" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="center-column">
                                            <img src="{{ image.href }}" width="100px" height="auto" />
                                        </div>
                                    </td>
                                    <td>
                                        {{ image.name }}
                                    </td>
                                    <td>
                                        {{ image.size }} bytes
                                    </td>
                                    <td>
                                        <div class="center-column">
                                            <p-button [rounded]="true" [text]="true" icon="pi pi-trash"
                                                (click)="removeImage(image)" />
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">{{ 'project.register.upload.empty' | transloco }}.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-template>
                </p-fileUpload>
                <p-messages 
                    id="messages" 
                    [(value)]="message" 
                    [enableService]="false"
                    [style]="{'font-size':'15px', 'width': '100%'}" />
            </div>
            <div class="row-btn">
              <p-button
                id="btnBack"
                name="btnBack"
                label="{{ 'project.register.backBtn' | transloco }}"
                severity="secondary"
                [raised]="true"
                (click)="backPage(2)"/>
              <p-button
                id="btnActive"
                name="btnActive"
                [label]="labelBtnImagePage"
                (click)="modalGoPage4()"
                severity="danger"
                [raised]="true"/>
            </div>  
        </div>
        <div class="modal-content-page4" [(style.display)]="modalpage[3]">
            <div>
                <div class="row">
                <label>{{ 'project.register.shareLabel' | transloco }}</label>
                </div>
                <div class="row">
                <input class="email" 
                    type="email"
                    id="email" name="email"
                    formControlName="email" 
                    placeholder="{{ 'project.register.email' | transloco }}" 
                    pInputText />
                <p-button
                    id="btnAdd"
                    name="btnAdd"
                    label="{{ 'project.register.addBtn' | transloco }}"
                    [raised]="true"
                    [style]="{'margin-left':'20px'}"
                    (click)="addShare()"/>
                </div>
            </div>
            <p-table
                *ngIf="project"
                #dtShare
                [value]="project.sharedWith"
                dataKey="id"
                [rows]="5"
                [rowsPerPageOptions]="[5, 15, 30]"
                [loading]="loadingShare"
                [paginator]="true"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{{ 'project.detail.tableShare.currentPage' | transloco }}"
                [tableStyle]="{ 'min-width': '45rem' }"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="email" style="width:35%">
                            {{ 'project.detail.tableShare.email' | transloco }} <p-sortIcon field="email" />
                        </th>
                        <th pSortableColumn="date" style="width:20%">
                            {{ 'project.detail.tableShare.date' | transloco }} <p-sortIcon field="date" />
                        </th>
                        <th style="width:15%">{{ 'project.detail.tableShare.action' | transloco }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-share let-ri="rowIndex">
                    <tr>
                        <td>
                            {{ share.email }}
                        </td>
                        <td>
                            {{ share.date | date: 'dd/MM/yyyy' }}
                        </td>
                        <td>
                            <p-button [rounded]="true" [text]="true" icon="pi pi-trash" (click)="removeShare(ri)"/>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">{{ 'project.detail.tableShare.noFound' | transloco }}.</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="row-btn">
                <p-button
                id="btnBack"
                name="btnBack"
                label="{{ 'project.register.backBtn' | transloco }}"
                severity="secondary"
                [raised]="true"
                (click)="backPage(3)"/>
                <p-button
                id="btnActive"
                name="btnActive"
                label="{{ 'project.register.finishBtn' | transloco }}"
                type="submit"
                severity="danger"
                [raised]="true"/>
            </div>
        </div>
    </form>
  </div>
  <p-toast key="toastRegisterProject"/>
</p-dialog>
