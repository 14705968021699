
<app-dashboard-template>
    <div class="container">
        <div class="banner"></div>
        <div class="banner-mobile"></div>
        <div class="banner-tablet"></div>
    </div>
    <div id="garage">
        <div id="add-project" class="cards-add-project" fragment="garage">
            <h1>{{ 'dashboard.garage' | transloco }}</h1>
            <p-button
                icon="pi pi-plus-circle" 
                id="addProjectBtn" name="addProjectBtn"
                [rounded]="true" 
                [text]="true" 
                size="large"
                (click)="addProject()" />
        </div>
        <div class="cards-search">
            <p-iconField iconPosition="left" class="ml-auto">
                <p-inputIcon  styleClass="pi pi-search" />
                <input class="input-search"
                    pInputText 
                    type="text" 
                    [(ngModel)]="filterValue"
                    (input)="applyFilter()" 
                    placeholder="{{ 'dashboard.search' | transloco }}" />
            </p-iconField>
        </div>
        <div class="centered-spinner" *ngIf="isLoading">
            <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
        </div>
        <div *ngIf="!isLoading">
            <div class="cards-project" *ngFor="let project of projects">
                <app-card-project [project]="project" [user]="userID" [shared]="false" (refreshDashboard)="refreshList()"></app-card-project>
            </div>
        </div>
    </div>

    <div id="sharedGarage">
        <div id="add-project" class="cards-add-project" fragment="sharedGarage">
            <h1>{{ 'dashboard.sharedGarage' | transloco }}</h1>
        </div>
        <div class="cards-search">
            <p-iconField iconPosition="left" class="ml-auto">
                <p-inputIcon  styleClass="pi pi-search" />
                <input class="input-search"
                    pInputText 
                    type="text" 
                    [(ngModel)]="filterValue"
                    (input)="applyFilterShared()" 
                    placeholder="{{ 'dashboard.search' | transloco }}" />
            </p-iconField>
        </div>
        <div class="centered-spinner" *ngIf="isLoadingShared">
            <p-progressSpinner styleClass="large-spinner"></p-progressSpinner>
        </div>        
        <div *ngIf="!isLoadingShared">
            <div class="card-plus" *ngIf="!(sharedProjects.length > 0)">
                <a>{{ 'dashboard.emptySharedProject' | transloco }}</a>
            </div>
            <div class="cards-project" *ngFor="let project of sharedProjects">
                <app-card-project [project]="project" [user]="userID" [shared]="true" (refreshDashboard)="refreshList()"></app-card-project>
            </div>
        </div>
    </div>
    <p-dialog
        id="modal-welcome"
        name="modal-welcome"
        [modal]="true"
        [draggable]="false"
        [(visible)]="showWelcomeDialog"
        (onHide)="closeModal()"
        [style]="{'width': '900px','height': '700px','border-radius':'130px'}">
        <div class="modal-content">
            <div class="modal-left">
                <h1>{{ 'vcu.welcome' | transloco }}</h1>
                <p>{{ 'vcu.welcome-msg' | transloco }}</p>
                <div class="btn-mobile">
                    <p-button
                        id="btnSubmitWelcomeMobile"
                        name="btnSubmitWelcomeMobile"
                        label="{{ 'vcu.welcome-btn' | transloco }}"
                        severity="danger"
                        [raised]="true"
                        (click)="closeModal()">
                    </p-button>
                </div>
            </div>
            <div class="modal-right">
                <div class="img"></div>
            </div>
            <p-button
                id="btnSubmitWelcome"
                name="btnSubmitWelcome"
                label="{{ 'vcu.welcome-btn' | transloco }}"
                severity="danger"
                [raised]="true"
                (click)="closeModal()">
            </p-button>
        </div>
    </p-dialog>
    <p-dialog
        id="modal-reactive-account"
        name="modal-reactive-account"
        [modal]="true"
        [draggable]="false"
        [(visible)]="showReactiveAccountDialog"
        (onHide)="closeModalReactiveAccount()"
        [style]="{'width': '900px','height': '700px','border-radius':'130px'}">
        <div class="modal-content">
            <div class="modal-left">
                <h1>{{ 'user.account.reactive.header' | transloco  }}</h1>
                <p>{{ reactiveAccountMessage }}</p>
                <div class="modal-footer">
                    <p-button
                    id="btnNo"
                    name="btnNo"
                    label="{{ 'user.account.reactive.no' | transloco }}"
                    severity="secondary"
                    [raised]="true"
                    (click)="logoutAccount()">
                    </p-button>
                    <p-button
                        id="btnReactiveAccount"
                        name="btnReactiveAccount"
                        label="{{ 'user.account.reactive.yes' | transloco }}"
                        severity="danger"
                        [raised]="true"
                        (click)="ReactiveAccount()">
                    </p-button>
                </div>
            </div>
            <div class="modal-right">
                <div class="img"></div>
            </div>
        </div>
    </p-dialog>
    <p-toast key="toastDashboard" />
    <app-vcu-identified [showDialog]="showVcuIdentifiedDialog" (closeDialog)="updateVcuIdentifiedDialog()"></app-vcu-identified>
    <app-register-project [showDialog]="showProjectDialog" (closeDialog)="updateProjectDialog()" [project]="null" [editMode]="false"></app-register-project>
</app-dashboard-template>