import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem('idToken');
    const shouldAddHeaders = this.shouldAddHeaders(req.url);
    
    if (shouldAddHeaders && idToken) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer ' + idToken
        })
      };

      const clonedRequest = req.clone({
        setHeaders: httpOptions.headers.keys().reduce((headers: Record<string, string>, key: string) => {
          headers[key] = httpOptions.headers.get(key) || '';
          return headers;
        }, {})
      });

      return next.handle(clonedRequest);
    }

    return next.handle(req);
  }

  private shouldAddHeaders(url: string): boolean {
    const urlPatternsToInclude = [
      '/user/',
      '/global/category'
    ];
    return urlPatternsToInclude.some(pattern => url.includes(pattern));
  }
}
