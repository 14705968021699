<div class="card" *ngIf="project"> <!-- *ngIf="project" -->
    <div class="card-img">
        <div class="img" [ngStyle]="{'background-image': imageStyle}"></div>
    </div>
    <div class="card-container">
        <div class="card-header">
            <div class="title">
                <h1 [ngClass]="h1Class">
                    <i [ngClass]="{'pi-star-fill': project.favorite, 'pi-star': !project.favorite}" 
                        class="pi star-icon"
                        (click)="showConfirm()"
                        *ngIf="!shared">
                    </i>
                    {{ project.name }}
                </h1>
                <p class="subtitle" [ngClass]="subtitleClass"> <!-- subtitle-shared -->
                    {{ 'project.card.sharedBy' | transloco }}: {{ project.sharedBy?.name }}
                </p>
            </div>
        </div>
        <div class="card-body">
            <div class="cols">
                <div class="left">
                    <strong *ngIf="equipments.length > 0" class="title">{{ 'project.card.equipments' | transloco }}</strong>
                    <ul>
                        <li *ngFor="let equipment of equipments">
                            {{equipment}}
                        </li>
                    </ul>
                </div>
                <div class="right">
                    <strong *ngIf="project.tags.length > 0" class="title">{{ 'project.card.tags' | transloco }}</strong>
                    <ul>
                        <li *ngFor="let tag of project.tags">
                            <p-tag value="{{tag}}" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card-options">
            <!-- <p-button class="shared" icon="pi pi-share-alt" [disabled]="disabledShare" /> -->
            <p-button 
                label="{{ 'project.card.detail' | transloco }}" 
                icon="pi pi-chevron-right" iconPos="right" 
                (onClick)="goProjectDetail()"
                severity="danger" />
        </div>
    </div>
</div>
<div class="polygon"></div>

<p-toast key="toast" />
<p-confirmDialog />
