import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Country } from '../../../model/country';
import { DocType } from '../../../model/doc-type';
import { CustomValidator } from 'src/shared/custom-validator';
import { Account } from '../../../model/form/account';
import { AuthService } from 'src/app/service/auth.service';
import { Title } from '@angular/platform-browser';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { Message, MessageService } from 'primeng/api';
import { CognitoException } from 'src/app/model/enum/cognito-exception';
import { COUNTRY_LIST } from 'src/app/model/mockup/country-mockup';
import { enviroment } from 'src/environments/environment';
import { CountryService } from 'src/app/service/country.service';
import { GeolocationService } from 'src/app/service/geolocationService';
import { PhoneCountry } from 'src/app/model/country';
import { Language } from 'src/app/model/language';
import { LANGUAGE_LIST } from 'src/app/model/mockup/language-mockup';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrl: './create-account.component.css'
})
export class CreateAccountComponent implements OnInit {
  
  public form!: FormGroup;

  countryOptions: Country[] = [];
  countries: Country[] = [];
  docType: DocType | null = null;
  loginUrl: string;
  activateAccountUrl: string;
  isBrasil: boolean = false;
  showBirthDate: boolean = true;
  placeHolderName: string = '';
  maxDate: Date | undefined;
  submitBtnLoading: boolean = false;
  messagesDocNumber: Message[] = [];
  messagesEmail: Message[] = [];
  messagesPassword: Message[] = [];
  messagesCookies: Message[] = [];
  messages: Message[] = [];
  backBtnLoading: boolean = false;
  accountInitiate!: Account;
  currentMask: string = '(99) 99999 9999';
  selectedCountry: any;
  languageCode: string | null;

  languageOptions: Language[];
  DEFAULT_LANGUAGE: Language;
  lastLanguage: Language;
  language: Language;
  
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService,
    private authService: AuthService,
    private countryService: CountryService,
    private messageService: MessageService,
    private geolocationService: GeolocationService) {

    this.loginUrl = '/login';
    this.activateAccountUrl = '/activate-account';
    this.languageOptions = LANGUAGE_LIST as [];
    this.DEFAULT_LANGUAGE = this.languageOptions[0];
    this.language = this.DEFAULT_LANGUAGE;
    this.lastLanguage = this.DEFAULT_LANGUAGE;
    this.languageCode = null;
  }
  
  async ngOnInit() {
      this.languageCode = localStorage.getItem('language') || null;

      this.changeLanguage(this.getLanguage(this.languageCode).name);
      this.createForm(new Account());
      this.refreshLanguageOptions();
      this.getLocation();
      this.accountInitiate = new Account();
      if(enviroment.MOCKUP) {
        this.countryOptions = COUNTRY_LIST;
        this.adaptForm();
      } else {
        this.getAllCountry();
      }
  }

  getAllCountry() {
    this.countryService.getAllCountry().subscribe({
      next: countrys => {
        this.countryOptions = CountryService.toCountryList(countrys);
        this.countries = CountryService.toCountryList(countrys);
        this.adaptForm();
      },
      error: _ => {
        this.countryOptions = [],
        this.countries = []
      }
    });
  }
  
  createForm(account: Account) {
    this.form = this.formBuilder.group({
      id: [account.id],
      country: [account.country, CustomValidator.getRequired()],
      name: [account.name, CustomValidator.getRequired()],
      docNumber: [account.docNumber],
      birthDate: [account.birthDate],
      phone: [account.phone, CustomValidator.getRequired()],
      phoneCountry: [account.phoneCountry, CustomValidator.getRequired()],
      email: [account.email, CustomValidator.getEmail()],
      password: [account.password, CustomValidator.getPassword()],
      passwordConfirm: [account.passwordConfirm, CustomValidator.getPassword()],
      language: [localStorage.getItem('language')],
      checkCookies: [account.checkCookies, CustomValidator.getCheckbox()],
      checkNewsletter: [account.checkNewsletter],
      inactive: [account.inactive],
      inactiveReason: [account.inactiveReason]
    });
  }

  updateMask() {
    const selectedCountry = this.form.get('phoneCountry')?.value;
    const country = this.countries.find(c => c.code === selectedCountry?.code);
  
    if (country) {
      this.currentMask = country.mask;
  
      const phoneControl = this.form.get('phone');
  
      if (phoneControl) {
        const currentPhoneValue = phoneControl.value || '';
        const isValidForNewMask = new RegExp(this.currentMaskToRegex(country.mask)).test(currentPhoneValue);
  
        this.f.phoneCountry.updateValueAndValidity();
      }
    }
  }
  
  
  // Converte a máscara para uma regex
  currentMaskToRegex(mask: string): string {
    return mask
      .replace(/9/g, '\\d')
      .replace(/\(/g, '\\(')
      .replace(/\)/g, '\\)')
      .replace(/\s/g, '\\s');
  }

  backLogin() {
    if(!this.deepEquals(this.form.value, this.accountInitiate)) {
      this.backBtnLoading = true;
      this.translocoService.selectTranslate('noSaved').subscribe(value => {
        this.messageService.clear();
        this.messageService.add({ key: 'toastCreateAccount', severity: 'error', summary: value});
      });

      setTimeout(() => {
        this.router.navigate([this.loginUrl]);
      }, 3000);  //3s
    } else {
      this.router.navigate([this.loginUrl]);
    }
  }

  deepEquals(form: Account, initiate: Account) : boolean {
    if(form && initiate) {
      if(form.country.id !== initiate.country.id) return false;
      if(!(form.docNumber === null && initiate.docNumber === '')) return false;
      if(form.name !== initiate.name) return false;
      if(form.birthDate?.getTime() !== initiate.birthDate?.getTime()) return false;
      if(this.removeMask(form.phone) !== this.removeMask(initiate.phone)) return false;
      if(form.email !== initiate.email) return false;
      if(form.password !== initiate.password) return false;
      if(form.passwordConfirm !== initiate.passwordConfirm) return false;
      if(form.checkCookies !== initiate.checkCookies) return false;
      if(form.checkNewsletter !== initiate.checkNewsletter) return false;
    }
    return true;
  }

  async onSubmit() {
    this.checkInputs();
    if(this.equalsPassword() && this.form.valid) {
      this.submitBtnLoading = true;
      await this.authService.signUp(this.form.value, localStorage.getItem('language')).then(() => {
        this.submitBtnLoading = false;
        localStorage.setItem("name-activate-account", this.f.name.value);
        localStorage.setItem("email-activate-account", this.f.email.value);
        this.router.navigate([this.activateAccountUrl], {
          state: { 
            name: this.f.name.value,
            email: this.f.email.value
          }
        });
      }).catch(error => {
        if(CognitoException.INVALID_PASSWORD === error.name) {
          this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => 
            this.messages = [{ severity: 'error', summary: value }]
          );
        } if(CognitoException.USERNAME_EXISTS === error.name) {
          this.translocoService.selectTranslate('user.createAccount.emailExist').subscribe(value => 
            this.messages = [{ severity: 'error', summary: value }]
          );
        } else if(CognitoException.LAMBDA_VALIDATION === error.name) {
          if(error.message && error.message.includes("REC-001")) {
            this.translocoService.selectTranslate('inputRequired').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value }]
            );
          } else if (error.message && error.message.includes("REC-002")) {
            this.translocoService.selectTranslate('user.createAccount.emailDocExist').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value }]
            );
          } else if (error.message && error.message.includes("REC-999")) {
            this.translocoService.selectTranslate('user.createAccount.undefinedError').subscribe(value => 
              this.messages = [{ severity: 'error', summary: value }]
            );
          }
        } else {
          this.translocoService.selectTranslate('errorConection').subscribe(value => 
            this.messages = [{ severity: 'error', summary: value}]
          );
        }
      });

      this.submitBtnLoading = false;
    } else {
      this.translocoService.selectTranslate('inputRequired').subscribe(value => 
        this.messages = [{ severity: 'error', summary: value }]
      );
    }
  }

  adaptForm() {
    if(this.countryOptions.length > 0 && this.countries.length > 0) {
      this.f.country.setValue(this.countryOptions[0])
      this.f.phoneCountry.setValue(this.countries[0])
      this.updateMask();
      
      //this.f.PhoneCountry.setValue(this.countries[0])
      this.accountInitiate.country = this.countryOptions[0]
    }
    // this.getMaskDocNumber();
    this.setPlaceHolderNameCpf();
    this.setTitle();
    this.setDateInterval();
  }

  checkInputs() {
    this.messages = [];
    this.messagesDocNumber = [];
    this.messagesEmail = [];
    this.messagesPassword = [];
    this.messagesCookies = [];
    if(this.f.country.invalid) this.f.country.markAsDirty();
    if(!this.checkName()) this.f.name.setErrors({'nomatch': true});
    if(this.f.name.invalid) this.f.name.markAsDirty();
    //TODO nao validar data se for cnpj
    if(this.f.birthDate.invalid) this.f.birthDate.markAsDirty();
    //TODO ??? remover mascara do numero de telefone?
    if(this.f.phone.invalid) this.f.phone.markAsDirty();
    if(this.f.email.invalid) this.invalidateEmail();
    if(this.f.password.invalid) this.f.password.markAsDirty();
    if(this.f.passwordConfirm.invalid) this.f.passwordConfirm.markAsDirty();
    if(this.f.checkCookies.invalid) {
      this.f.checkCookies.markAsDirty();
      this.translocoService.selectTranslate('user.createAccount.cookiesTrue').subscribe(value => 
        this.messagesCookies = [{ severity: 'error', summary: value }]
      );
    }
    //if(this.f.docNumber.invalid) this.f.docNumber.markAsDirty();
  }

  checkName() : boolean {
    let name = this.f.name.value;
    if(name === null) return false;

    name = name.trim();
    return (name.split(" ").length > 1 ? true : false);
  }

  checkEmail() {
    this.removeMessageEmail();
    if(this.f.email.invalid) {
      this.invalidateEmail();
    }
  }

  equalsPassword() : boolean {
    if(this.f.password.value !== this.f.passwordConfirm.value) {
      this.translocoService.selectTranslate('user.createAccount.passwordEquals').subscribe(value => 
        this.messagesPassword = [{ severity: 'error', summary: value }]
      );
      return false;
    } else if(this.f.password.invalid || this.f.passwordConfirm.invalid) {
      this.translocoService.selectTranslate('user.forgotPassword.invalidPassword').subscribe(value => 
        this.messagesPassword = [{ severity: 'error', summary: value }]
      );
      return false;
    }
    return true;
  }

  setTitle() {
    this.translocoService.selectTranslate('title.create-account').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  getMaskDocNumber() {
    if(this.isBrasil) {
      this.removeMessageCPFCNPJ();
      let docNumber = this.removeMask(this.f.docNumber.value);
      if(docNumber.length === 11) {
        docNumber = this.addMaskCPF(docNumber);
        this.f.docNumber.setValue(docNumber);
        if(this.validCPF(docNumber)) {
          this.docType = DocType.CPF;
          this.setPlaceHolderNameCpf();
          this.showBirthDate = true;
        } else {
          this.invalidateCPF();
        }
      } else if(docNumber.length === 14) {
        docNumber = this.addMaskCNPJ(docNumber);
        this.f.docNumber.setValue(docNumber);
        if(this.validCNPJ(docNumber)) {
          this.docType = DocType.CNPJ;
          this.setPlaceHolderNameCnpj();
          this.showBirthDate = false;
        } else {
          this.invalidateCNPJ();
        }
      } else {
        this.f.docNumber.setValue('');
      }
    } else {
      this.showBirthDate = true;
    }
  }

  setPlaceHolderNameCpf() {
    this.translocoService.selectTranslate('user.createAccount.fullName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }
  setPlaceHolderNameCnpj() {
    this.translocoService.selectTranslate('user.createAccount.companyName').subscribe(value => 
      this.placeHolderName = value+'*'
    );
  }

  private removeMessageCPFCNPJ() {
    this.messagesDocNumber = [];
  }

  private removeMessageEmail() {
    this.messagesEmail = [];
  }

  private invalidateCPF() {
    this.f.docNumber.markAsDirty();
    this.f.docNumber.setErrors({'nomatch': true});
    this.translocoService.selectTranslate('user.createAccount.cpfValid').subscribe(value => 
      this.messagesDocNumber = [{ severity: 'error', summary: value }]
    );
  }

  private invalidateCNPJ() {
    this.f.docNumber.markAsDirty();
    this.f.docNumber.setErrors({'nomatch': true});
    this.translocoService.selectTranslate('user.createAccount.cnpjValid').subscribe(value => 
      this.messagesDocNumber = [{ severity: 'error', summary: value }]
    );
  }

  private invalidateEmail() {
    this.f.email.markAsDirty();
    this.translocoService.selectTranslate('user.createAccount.emailValid').subscribe(value => 
      this.messagesEmail = [{ severity: 'error', summary: value }]
    );
  }

  private validCPF(value: string) : boolean {
    return cpf.isValid(this.removeMask(value));
  }

  private validCNPJ(value: string) : boolean {
    return cnpj.isValid(this.removeMask(value));
  }

  private addMaskCPF(str: string) : string { // '999.999.999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d)/,"$1.$2");
    str = str.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
    return str;
  }

  private addMaskCNPJ(str: string) : string { // '99.999.999/9999-99'
    if(str === null) return "";
    str = str.replace(/\D/g,"");
    str = str.replace(/^(\d{2})(\d)/,"$1.$2")
    str = str.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    str = str.replace(/\.(\d{3})(\d)/,".$1/$2")
    str = str.replace(/(\d{4})(\d)/,"$1-$2")
    return str;
  }

  private removeMask(str: string) : string {
    if(str === null) return "";
    str = str.replaceAll(".","");
    str = str.replaceAll("-","");
    str = str.replaceAll("/","");
    return str;
  }

  setIsBrasil(event: any): void {
    const selectedCountry = event.value;
    if (selectedCountry && (selectedCountry.name === 'Brasil' || selectedCountry.name === 'Brazil')) {
      this.isBrasil = true;
      this.f.docNumber.setValidators(CustomValidator.getRequired());
    } else {
      this.isBrasil = false;
      this.f.docNumber.clearValidators();
    }
    this.f.docNumber.updateValueAndValidity();
  }

  setDateInterval() {
    let today = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(today.getDate() - 1);
  }

  // setDateInterval() {
  //   let today = new Date();
  //   let year = today.getFullYear();
  //   let prevYear = year - 130;
  //   let nextYear =year - 14;
  //   this.minDate = new Date();
  //   this.minDate.setFullYear(prevYear);
  //   this.maxDate = new Date();
  //   this.maxDate.setFullYear(nextYear);
  // }

  changeCookies() {
    if(this.f.checkCookies.value) {
      this.messagesCookies = [];
    }
  }


  fixLanguage(event:any) {
    this.language = event.option;    
    if(this.lastLanguage !== this.language) {
        this.languageOptions
          .filter(opt => opt.name === this.language.name)
          .map(cty => cty.disabled = true)
        this.languageOptions
          .filter(opt => opt.name !== this.language.name)
          .map(cty => cty.disabled = false)

        this.lastLanguage = this.language;
        this.changeLanguage(this.language.name);
        localStorage.setItem('language', this.language.name);
        this.languageCode = this.language.name;
        this.getAllCountry();
    }
  }

  getLanguage(lan: string | null) : Language {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === lan) {
        return this.languageOptions[i];
      }
    }
    return this.DEFAULT_LANGUAGE;
  }

  public changeLanguage(languageCode: string | null): void {
    if(languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  getLocation(){
    this.geolocationService.getCountry().subscribe((data: any) => {
      if (data.country === "BR") {
        this.language = this.languageOptions[0];
      } else {
        this.language = this.languageOptions[1];
      }
    });
  }

  refreshLanguageOptions() {
    for (let i = 0; i < this.languageOptions.length; i++) {
      if(this.languageOptions[i].name === this.language.name)
        this.languageOptions[i].disabled = true;
      else
       this.languageOptions[i].disabled = false;
    }
  }


  get f() { return this.form.controls; }
}
