import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from "primeng/message"; 
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CarouselModule } from 'primeng/carousel';
import { ImageModule } from 'primeng/image';

import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RegisterVCUComponent } from './register-vcu/register-vcu.component';
import { RegisterProjectComponent } from './register-project/register-project.component';
import { InputOtpModule } from 'primeng/inputotp';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule} from '../../share/share.module';



@NgModule({
  declarations: [
    RegisterVCUComponent,
    RegisterProjectComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    TranslocoRootModule,
    TagModule,
    DialogModule,
    StepsModule,
    ReactiveFormsModule,
    ChipsModule,
    CalendarModule,
    InputTextareaModule,
    FileUploadModule,
    MessagesModule,
    MessageModule,
    RadioButtonModule,
    AccordionModule,
    TableModule,
    InputIconModule,
    IconFieldModule,
    CarouselModule,
    ImageModule,
    FormsModule,
    InputOtpModule,
    ConfirmDialogModule,
    InputSwitchModule,
    ToastModule,
    ProgressSpinnerModule,
    SharedModule
  ],
  exports: [
    RegisterVCUComponent,
    RegisterProjectComponent
  ],
  providers: [provideAnimations(), provideAnimationsAsync(), ConfirmationService]
})
export class RegisterModule { }
