
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Vehicle } from "src/app/model/vehicle";
import { PublicProjectResponse, VcuResponse, Project, Vcu, Image } from '../public-project-response';
import { PublicService } from '../service.service';
import { IMAGE_DEFAULT } from 'src/app/model/mockup/image-mockup';



@Component({
  selector: 'app-public-project',
  templateUrl: './public-project.component.html',
  styleUrl: './public-project.component.css'
})
export class PublicProjectComponent implements OnInit {

  vcus: Vcu[];
  project: Project | null;
  publicEquipaments: string[] = [];
  responsiveOptions: any[] | undefined;
  vehicle = new Vehicle();
  vcu: Vcu | undefined;
  idAux: number = 0;
  dataProject: PublicProjectResponse | undefined; 
  publicUser: string = ''; 
  publicProject : string = ''; 
  publicLanguage: string = '';
  

  constructor(
    private publicService: PublicService,
    private route: ActivatedRoute,
  ) {
    this.project = null;
    this.vcus = []
  }

  ngOnInit(): void {
    
    this.route.params.subscribe(params => {
      this.publicUser = params['user'];
      this.publicProject = params['project'];
      // this.publicLanguage = params['lang'];
    });
    this.getLocation();

    if(this.publicUser && this.publicProject)this.getProject();

      this.responsiveOptions = [
        {
          breakpoint: '1430px',
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: '1260px',
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: '870px',
          numVisible: 1,
          numScroll: 1,
        },
      ];
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getLocation(){
    this.publicService.getCountry().subscribe((data: any) => {
      this.publicLanguage = data.country;
    });
  }

  getProject() {
    this.publicService.getPublicProject(this.publicUser, this.publicProject).subscribe((data) => {
      this.dataProject = data;
      if (this.dataProject) {
        if (this.dataProject.attributes && this.dataProject.attributes.photo && this.dataProject.attributes.primaryImage) {
          let photo = this.dataProject.attributes.photo;
          const primaryImage = this.dataProject.attributes.primaryImage;
          let photoArray = photo.split(',');
          const primaryIndex = photoArray.indexOf(primaryImage);
          if (primaryIndex !== -1) {
            photoArray.splice(primaryIndex, 1);
            photoArray.unshift(primaryImage);
          }
          photo = photoArray.join(',');
          this.dataProject.attributes.photo = photo;
        }

        this.project = this.toPublicProject(this.dataProject);
  
        this.vcus = this.project?.vcus ?? [];
        if(this.vcus.length > 0) {
          this.vcus.forEach(vcu => {
            if(vcu.model)
            this.publicEquipaments.push(vcu.model);
          })
        }
        
        if(this.dataProject.equipments && this.dataProject.equipments.length > 0) {
          this.dataProject.equipments.forEach(equip => {
            this.publicEquipaments.push(equip);
          });
        }
        this.setImageDefault();
      }



    });
  }

  setImageDefault() {
    if(this.project && this.project.images.length < 5) {
      for (let i = this.project.images.length; i < 5; i++) {
        this.project.images.push(IMAGE_DEFAULT);
      }
    }
  }


    toPublicProject(projectResponse: PublicProjectResponse): Project {          
      return new Project(
        projectResponse.projectName,
        new Vehicle(
          0, 
          projectResponse.attributes?.manufacturer,
          projectResponse.attributes?.['vehicle-model'],
          (projectResponse.attributes?.['date-of-manufacture'] ? new Date(projectResponse.attributes?.['date-of-manufacture']) : null)
        ),
        this.vcuResponseToVcu(projectResponse.vcus),
        projectResponse.equipments,
        this.stringToImage(projectResponse.attributes?.photo,'photo'),
        null,  
        null,
      );
    }


    stringToImage(str: string | null, typeFile: string) : Image[] {
      let path = this.dataProject?.path;
      if (path) {
        const [userID, projectID] = path.split('|');

        if(!str || str === '') return [];
        let images: Image[] = []
        let imgsStr: string[] = str.split(',')
        let url = `https://hml-api.fueltech.cloud/files/user/${userID}/project/${projectID}/${typeFile}`;
    
        imgsStr.forEach((name, index) => {
            images.push(new Image(index, name.trim(), `${url}/${name}`));
        });
    
        return images ;
      } else {
        return [];
      }


    }

    vcuResponseToVcu(vcusResp: VcuResponse[]) : Vcu[] {
      if(!vcusResp) return [];
  
      let vcus: Vcu[] = []
      vcusResp.map(vcu => 
        vcus.push(this.toVcu(vcu))
      );
      return vcus;
    }

    toVcu(vcu: VcuResponse) : Vcu {
        if(vcu) {
          return new Vcu(
            vcu.nickname,
            vcu.model,
          );
        }
        return new Vcu();
      }
}
