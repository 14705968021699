import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamicDateHour'
})
export class DynamicDateHourPipe implements PipeTransform {
  transform(value: Date | string | null, locale: string): string {
    if (!value) return '';
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };

    return new Intl.DateTimeFormat(locale, options).format(date);
  }
}
