  <div class="g-container">

      <div class="g-card">
        <div class="g-header">
            <div class="g-left">
                <h1>{{ project?.name }}</h1>                  
            </div>
        </div>
    </div>
      <h1>
        {{ publicLanguage === 'BR' ? 'FOTOS' : 'PHOTOS' }} </h1>  
      <p-carousel 
          [value]="project?.images ?? []" 
          [numVisible]="3" 
          [numScroll]="3" 
          [circular]="true" 
          [showIndicators]="false"
          [responsiveOptions]="responsiveOptions"
          >
          <ng-template let-image pTemplate="item">
              <p-image 
                  [src]="image.href"
                  [alt]="image.name"
                  [width]="'100%'"
              />
          </ng-template>
      </p-carousel>
      <h1>
        {{ publicLanguage === 'BR' ? 'Equipamentos Utilizados' : 'Equipment Used' }}
      </h1>
      <div *ngFor="let equip of publicEquipaments">
        <h4>{{ equip }}</h4>
      </div>
      <h1>
        {{ publicLanguage === 'BR' ? 'Dados do Veículo' : 'Vehicle Data' }}
      </h1>
      <div>
        <h4>
          <span>{{ publicLanguage === 'BR' ? 'FABRICANTE: ' : 'MANUFACTURER: ' }}:</span> {{ project?.vehicle?.manufacturer === '' ? publicLanguage === 'BR' ? 'Não Informado' : 'Not Informed' : project?.vehicle?.manufacturer }}
        </h4>
        <h4>
          <span>{{ publicLanguage === 'BR' ? 'MODELO: ' : 'MODEL: ' }}:</span> {{ project?.vehicle?.model === '' ? publicLanguage === 'BR' ? 'Não Informado' : 'Not Informed' : project?.vehicle?.model }}
        </h4>
        <h4>
          <span>{{ publicLanguage === 'BR' ? 'ANO: ' : 'YEAR: ' }}:</span> {{ project?.vehicle?.yearDate === null ? publicLanguage === 'BR' ? 'Não Informado' : 'Not Informed' : project?.vehicle?.yearDate | date: 'yyyy' }}
        </h4>
      </div>
  </div>


