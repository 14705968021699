import { Equipment } from "../equipment";
import { Image } from "../Image";
import { Item } from "../Item";
import { MapProject } from "../map-project";
import { Share } from "../share";
import { Vehicle } from "../vehicle";
import { Vcu } from "./vcu";
import { LogProject } from '../log-project';
import { Status } from 'src/app/model/enum/status';
import { ProjectAttributes } from "../project-attributes";

export class Project {

    id: string;
    favorite: boolean;
    name: string;
    status: Status | null;
    application: Item | null;
    vehicle: Vehicle | null;
    tags: string[];
    detail: string;
    vcus: Vcu[];
    equipments: Equipment[];
    images: Image[];
    primaryImage: Image | null;
    sharedBy: Share | null;
    sharedWith: Share[];
    maps: MapProject[];
    logs: LogProject[];
    instagram: string;
    attributes?: ProjectAttributes | null;
    userID: string;
    ownerUser: string | null;
    isSharing: boolean = false;

    constructor(
        id = '',
        favorite = true,
        name = '',
        status: Status | null = null,
        application: Item | null = null,
        vehicle: Vehicle | null = null,
        tags = new Array<string>(),
        detail = '',
        vcus = new Array<Vcu>(),
        equipments = new Array<Equipment>(),
        images = new Array<Image>(),
        primaryImage: Image | null = null,
        sharedBy: Share | null = null,
        sharedWith = new Array<Share>(),
        maps = new Array<MapProject>(),
        logs = new Array<LogProject>(),
        instagram = '',
        attributes: ProjectAttributes | null = null,
        userID = '',
        ownerUser = '',
        isSharing = false
    ) {
        this.id = id;
        this.favorite = favorite;
        this.name = name;
        this.status = status;
        this.application = application;
        this.vehicle = vehicle;
        this.tags = tags;
        this.detail = detail;
        this.vcus = vcus;
        this.equipments = equipments;
        this.images = images;
        this.primaryImage = primaryImage;
        this.sharedBy = sharedBy;
        this.sharedWith = sharedWith;
        this.maps = maps;
        this.logs = logs;
        this.instagram = instagram;
        this.attributes = attributes
        this.userID = userID;
        this.ownerUser = ownerUser;
        this.isSharing = isSharing;
    }
}
