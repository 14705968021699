import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TagModule } from 'primeng/tag';

import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CardVcuComponent } from './card-vcu/card-vcu.component';
import { CardProjectComponent } from './card-project/card-project.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule} from '../../share/share.module';

@NgModule({
  declarations: [
    CardVcuComponent,
    CardProjectComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    HttpClientModule,
    ButtonModule,
    TagModule,
    ToastModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    SharedModule
  ],
  exports: [
    CardVcuComponent,
    CardProjectComponent
  ],
  providers: [provideAnimations(), provideAnimationsAsync(), ConfirmationService]
})
export class CardModule { }
