import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { MenuItem, MessageService} from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';
import { Project } from 'src/app/model/form/project';
import { Title } from '@angular/platform-browser';
import { ProjectService } from '../../../service/project.service';
import { Vehicle } from "../../../model/vehicle";
import { Image } from "../../../model/Image";
import { AccountService } from 'src/app/service/account.service';
import { Account } from 'src/app/model/form/account';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  token: string | null = '';
  modalVisible: boolean;
  showWelcomeDialog: boolean;
  showVcuIdentifiedDialog: boolean;
  showProjectDialog: boolean;
  sidebarVisible: boolean;
  items: MenuItem[] | undefined;
  projects: Project[];
  projectsBackup: Project[];
  sharedProjects: Project[];
  sharedProjectsBackup: Project[];
  vehicle: Vehicle = new Vehicle();
  image: Image = new Image();
  userID: string = '';
  filterValue: string = '';
  isLoading: boolean = false;
  isLoadingShared: boolean = false;
  showReactiveAccountDialog: boolean;
  reactiveAccountMessage: string = '';

  constructor(
      private activatedRoute: ActivatedRoute,
      private authService: AuthService, 
      private titleService: Title,
      private translocoService: TranslocoService,
      private projectService: ProjectService,
      private accountService: AccountService,
      private messageService: MessageService,) {
    this.modalVisible = true;
    this.showWelcomeDialog = false;
    this.showVcuIdentifiedDialog = false;
    this.showProjectDialog = false;
    this.sidebarVisible = false;
    this.projects = [];
    this.sharedProjects = [];
    this.projectsBackup = [];
    this.sharedProjectsBackup = [];
    this.isLoading = false;
    this.isLoadingShared = false;
    this.showReactiveAccountDialog = false;
  }

  verify() {
    let token = localStorage.getItem('token');
    if(token)
      this.authService.verifyToken(token);
  }
  refresh() {
    let refreshToken = localStorage.getItem('refreshToken');
    if(refreshToken)
      this.authService.refreshAuth();
  }
  revoke() {
    let token = localStorage.getItem('refreshToken');
    if(token)
      this.authService.revokeToken(token);
  }

  ngOnInit() {
    this.isLoading = false;
    this.isLoadingShared = false;
    this.token = localStorage.getItem('token');
    this.changeLanguage();
    this.setTitle();
    this.activatedRoute.fragment.subscribe((fragment: string | null) => {
      if (fragment) this.jumpToSection(fragment);
    });
    if(this.token) this.accountService.findUserActive().subscribe(
      data =>{
        this.userID = data.userID;
        this.findAllProjectSummary(data.userID);
        this.findAllSharedProjectSummary(data.userID);
        if(data.firstAccess) {
          this.showWelcomeDialog = true;
          this.accountService.getAccount(this.userID).subscribe(userData => {
            let account: Account = AccountService.toAccount(userData);
            account.firstAccess = false;
            this.accountService.updateAccount(account).subscribe();
          });
        }
        this.accountService.getAccount(this.userID).subscribe({
          next: (response) =>{
            if(response.daysLeft){
              this.showReactiveAccountDialog = true;
              this.translocoService.selectTranslateObject('user.account.reactive').subscribe(value => {
                this.reactiveAccountMessage = `${value.messageOne} ${response.daysLeft} ${value.messageTwo}`
              }).unsubscribe();
            }
          },
          error: (error) =>{}
        });
      });
  }

  jumpToSection(section: string | null) {
    if (section) document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
  }
  
  addVcu() {
    this.showVcuIdentifiedDialog = true;
  }
  updateWelcomeDialog() {
    this.showWelcomeDialog = false;
  }
  updateVcuIdentifiedDialog() {
    this.showVcuIdentifiedDialog = false;
    this.findAllProjectSummary(this.userID);
  }
  addProject() {
    this.showProjectDialog = true;
  }
  updateProjectDialog() {
    this.showProjectDialog = false;
    this.findAllProjectSummary(this.userID);

  }

  closeModal() {
    this.showWelcomeDialog = false;
  }

  setTitle() {
    this.translocoService.selectTranslate('title.dashboard').subscribe(value => 
      this.titleService.setTitle(value)
    );
  }

  public changeLanguage(languageCode?: string | null): void {
    if(!languageCode || languageCode === null)
      languageCode = localStorage.getItem('language');
    if(languageCode && languageCode !== null)
      this.translocoService.setActiveLang(languageCode);
  }

  findAllProjectSummary(userID: string | null){  
    if(userID === null) return;
    this.isLoading = true;
    this.projectService.findAllProjectSummary(userID,'AC','AC,AP').subscribe({
      next: (data) =>{
        if(data) {
          this.projects = this.projectService.projectSummaryToProject(data);
          this.projectsBackup = this.projectService.projectSummaryToProject(data);
        }
        this.isLoading = false;
      },
      error: (error) =>{
        this.isLoading = false;
      }
    });
  }

  findAllSharedProjectSummary(userID: string | null){  
    if(userID === null) return;
    this.isLoadingShared = true;
    this.projectService.FindProjectsSharedSummary(userID).subscribe({
      next: response =>{
        this.isLoadingShared = false;
        if(response) {
          this.sharedProjects = this.projectService.projectSummaryToProject(response);
          this.sharedProjectsBackup = this.projectService.projectSummaryToProject(response);
        }
      },
      error: _=>{
        this.isLoadingShared = false;
      }
    });
  }

  applyFilter() {
    let filter: string = this.filterValue.trim().toLowerCase();
    this.projects = this.projectsBackup;
    if(filter !== '') {
      this.projects = this.projectsBackup.filter(p => 
        p.name.toLowerCase().includes(filter));
    }
  }

  applyFilterShared() {
    let filter: string = this.filterValue.trim().toLowerCase();
    this.projects = this.projectsBackup;
    if(filter !== '') {
      this.projects = this.projectsBackup.filter(p => 
        p.name.toLowerCase().includes(filter));
    }
  }

  refreshList(){
    let userID = localStorage.getItem('idUser');
    this.findAllProjectSummary(userID);
    this.findAllSharedProjectSummary(userID);
  }

  closeModalReactiveAccount(){
    this.showReactiveAccountDialog = false;
  }

  ReactiveAccount(){
    this.translocoService.selectTranslateObject('user.account.reactive').subscribe(value => {
      let account = new Account();
      account.id = this.userID;
      account.inactive = false;
      this.messageService.clear();
      this.accountService.updateAccount(account).subscribe({
        next: _=>{
          this.showReactiveAccountDialog = false;
          this.messageService.add({ key: 'toastDashboard', severity: 'success', summary: value.header, detail: value.success});
        },
        error: _=>{
          setTimeout(() => {
            this.messageService.clear();
            this.messageService.add({ key: 'toastDashboard', severity: 'error', summary: value.header, detail: value.error});
          }, 10000);
          this.logoutAccount();
        }
      });
    }).unsubscribe();
  }

  logoutAccount(){
    this.authService.logout();
  }
}
